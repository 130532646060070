window.seller_store =
    {
        base: {},
        product: {},
        checkout: {},
        cart: {},
        upsell: {},
        discount: {},
        abandoned_cart: {},
        pick_up: {},
        collection: {},
        search: {},
        order_bump: {},
        review: {}
    }
