seller_store.pick_up = {

    changeLocation: function () {
        $('body').on('click', '#pick-up-location-list a.list-group-item', function () {
            $('#pick-up-location-list a.list-group-item').removeClass('active');
            $(this).addClass('active');
            $('#pick-up-select').empty();
            $('#pick-up-location-date').val('');
            $('#user_pick_up_start_at').val('');
            $('#user_pick_up_end_at').val('');

            $('#pick-up-address').val($(this).attr('data-address'));
            $('#pick-up-city').val($(this).attr('data-city'));
            $('#pick-up-county').val($(this).attr('data-county'));
            $('#pick-up-zip').val($(this).attr('data-zip'));
            $('#pick-up-country').val($(this).attr('data-country'));

            return false;
        });
    },

    changeTime: function () {
        $('#pick-up-select').change(function () {
            seller_store.pick_up.updateHiddenFields($(this.val()));
        });
    },

    updateHiddenFields: function (value) {
        var timeStart = value.split('|')[0],
            timeEnd = value.split('|')[1];

        $('#user_pick_up_start_at').val(timeStart);
        $('#user_pick_up_end_at').val(timeEnd);
    },

    updateTimes: function () {
        $('#pick-up-location-date').change(function () {
            $('#updatingCartCheckout').modal('show');

            var selectPickUpTime = $('#pick-up-select'),
                locationId = $('#pick-up-location-list .list-group-item.active').attr('data-location-id'),
                pickUpDate = $(this).val();

            selectPickUpTime.empty();

            $.ajax(
                {
                    url: '/pick_ups/locations/' + locationId + '?date=' + pickUpDate,
                    type: 'GET',

                    dataType: 'json',
                    success: function (data) {
                        for (var i = 0; i < data.times.length; i++) {
                            var item = data.times[i];
                            selectPickUpTime.append('<option value="' + item.value + '">' + item.key + '</option>');
                        }
                        seller_store.pick_up.updateHiddenFields(selectPickUpTime.val());
                        $('#pick-up-time-error').hide();
                        $('#updatingCartCheckout').modal('hide');
                    },
                    error: function (xhr, evt, status) {
                        $('#pick-up-time-error').show();
                        $('#updatingCartCheckout').modal('hide');
                    }
                });

            return false;
        });
    },

    disablePickUpAddress: function (result) {
        $('#pick_up_delivery_address_first_name').attr('disabled', result);
        $('#pick_up_delivery_address_last_name').attr('disabled', result);
        $('#pick_up_delivery_address_phone').attr('disabled', result);
        $('#pick-up-address').attr('disabled', result);
        $('#pick-up-city').attr('disabled', result);
        $('#pick-up-county').attr('disabled', result);
        $('#pick-up-zip').attr('disabled', result);
        $('#pick-up-country').attr('disabled', result);
    },

    disableStandardAddress: function (result) {
        $('#delivery_address_first_name').attr('disabled', result);
        $('#delivery_address_last_name').attr('disabled', result);
        $('#delivery_address_company').attr('disabled', result);
        $('#delivery_address_address').attr('disabled', result);
        $('#delivery_address_city').attr('disabled', result);
        $('#delivery_address_county').attr('disabled', result);
        $('#delivery_address_zip').attr('disabled', result);
        $('#delivery_address_country_id').attr('disabled', result);
    }
}