seller_store.order_bump = {

    create: function (bumpId) {
        var variantId = $('#bump_' + bumpId + ' #variant_id').val();

        $.ajax(
            {
                url: '/carts/order_bumps/' + bumpId + '/add_to_cart',
                data: {variant_id: variantId},
                type: 'POST',
                dataType: 'json',
                success: function (data) {
                    $("#order-summary-cart-items").append(data.order_bump_cart_item);
                    seller_store.checkout.updateCheckoutSummary(data);
                    $('.add-bump-item[data-bump-id="' + bumpId + '"]').hide().attr('disabled', true);
                    $('.remove-bump-item[data-bump-id="' + bumpId + '"]').show().attr('disabled', false);
                    $('#updatingCartCheckout').modal('hide');
                },
                error: function (xhr, evt, status) {
                    seller_store.base.addFlash('danger', $.parseJSON(xhr.responseText).errors[0])
                    $('#updatingCartCheckout').modal('hide');
                }
            });
    },

    checkboxUpdate: function () {
        $('#order_bump_checkbox').change(function () {
            $('#updatingCartCheckout').modal('show');
            var bumpId = $(this).attr('data-bump-id');

            if (this.checked) {
                seller_store.order_bump.create(bumpId);
            } else {
                seller_store.order_bump.remove(bumpId);
            }
        });
    },

    remove: function (bumpId) {
        $('#updatingCartCheckout').modal('show');
        $.ajax(
            {
                url: '/carts/order_bumps/' + bumpId + '/remove_from_cart',
                type: 'DELETE',
                dataType: 'json',
                success: function (data) {
                    seller_store.checkout.updateCheckoutSummary(data);
                    $('#order-summary-cart-items .order-bump-cart-item-' + bumpId).remove();
                    $('.remove-bump-item[data-bump-id="' + bumpId + '"]').hide().attr('disabled', true);
                    $('.add-bump-item[data-bump-id="' + bumpId + '"]').show().attr('disabled', false);
                    $('#updatingCartCheckout').modal('hide');
                },
                error: function (xhr, evt, status) {
                    seller_store.base.addFlash('danger', 'There was an error when removing the order bump. Please try again.')
                    $('#updatingCartCheckout').modal('hide');
                }
            });
    },

    changeVariant: function () {
        $('body').on('change', '.product-order-bump .option-value-select', function () {
            seller_store.order_bump.singleVariantUpdate($(this));
        });
    },

    singleVariantUpdate: function (optionValueSelect) {
        var bumpId = optionValueSelect.attr('data-bump-id'),
            productId = optionValueSelect.attr('data-product-id'),
            overridePrice = optionValueSelect.attr('data-override-price');

        $.ajax(
            {
                url: '/products/' + productId + '/variants/option_value',
                data: {option_values: optionValueSelect.val()},
                type: "POST",
                dataType: "json",
                success: function (data) {
                    if (overridePrice !== "true") {
                        $('#bump_' + bumpId + ' .bump-price').text(data.price);
                    }
                    $('#bump_' + bumpId + ' #variant_id').val(data.uid);
                }
            });
    },
}