seller_store.product =
    {

        increaseQuantity: function (elem) {
            var newValue = Number(elem.val()) + 1;
            elem.val(newValue);
        },

        decreaseQuantity: function (elem) {
            var newValue = Number(elem.val()) - 1;
            if (newValue < 1) {
                elem.val(1);
            } else {
                elem.val(newValue);
            }
        },

        changeVariant: function () {
            $('body').on('change', '#productDetails .option-value-select', function () {
                seller_store.product.variantDataUpdate();
            });
        },

        changeSubscription: function () {
            $('body').on('change', '#productDetails #cart_item_subscription', function () {
                seller_store.product.variantDataUpdate();
            });
        },

        changeGift: function () {
            $('body').on('change', '#productDetails #cart_item_gift', function () {
                seller_store.product.variantDataUpdate();
            });
        },

        changeSaleOption: function () {
            $('body').on('change', '#productDetails input[name="cart_item[sale_option]"]', function () {
                seller_store.product.variantDataUpdate();
            });
        },

        variantDataUpdate: function () {
            var optionValueSelect = $('select.option-value-select');
            if (optionValueSelect.length == 0) {
                seller_store.product.masterVariantUpdate();
            } else {
                seller_store.product.singleVariantUpdate(optionValueSelect);
            }
        },

        masterVariantUpdate: function () {
            var productSaleOptionCount = $('#productDetails').data('sale-option-count'),
                saleOption = productSaleOptionCount > 1 ? $('input[name="cart_item[sale_option]"]:checked').val() : $('input[name="cart_item[sale_option]"]').val(),
                planId = (saleOption === 'subscription') ? $('#cart_item_subscription').val() : $('#cart_item_gift').val(),
                productSaleOptions = window.productOptions[saleOption][0],
                productId = $('#productDetails'). attr('data-product-id');
            $('#cart_item_plan_id').val(planId);
            if (productSaleOptions.plans !== undefined) {
                for (_i = 0, _len = productSaleOptions.plans.length; _i < _len; _i++) {
                    var plan = productSaleOptions.plans[_i];

                    if (plan.plan_id === Number(planId)) {
                        var selectedOption = plan;
                        break;
                    }
                }
            } else {
                selectedOption = productSaleOptions
            }
            seller_store.product.variantUpdateContentChanges(selectedOption, saleOption);
            if (productSaleOptions.product_image_id !== null) {
                var index = $('.product-carousel-' + productId + ' .carousel-item[data-image-id="' + productSaleOptions.product_image_id + '"]').attr('data-index');
                $('#carousel-thumb').carousel(Number(index));
            }
        },

        singleVariantUpdate: function (optionValueSelect) {
            var option_value_ids = optionValueSelect.map(function () {
                    return Number($(this).val());
                }).get(),
                productSaleOptionCount = $('#productDetails').data('sale-option-count'),
                saleOption = productSaleOptionCount > 1 ? $('input[name="cart_item[sale_option]"]:checked').val() : $('input[name="cart_item[sale_option]"]').val(),
                planId = (saleOption === 'subscription') ? $('#cart_item_subscription').val() : $('#cart_item_gift').val(),
                productSaleOptions = window.productOptions[saleOption],
                productId = $('#productDetails').attr('data-product-id');
            $('#cart_item_plan_id').val(planId);
            for (_i = 0, _len = productSaleOptions.length; _i < _len; _i++) {
                var json = productSaleOptions[_i];
                if (seller_store.base.arraysEqual(json.option_value_ids, option_value_ids)) {
                    var selectedOption = json;
                    break;
                }
            }
            if (selectedOption.plans !== undefined) {
                for (_i = 0, _len = selectedOption.plans.length; _i < _len; _i++) {
                    var plan = selectedOption.plans[_i];

                    if (plan.plan_id === Number(planId)) {
                        var selectedOption = plan;
                        break;
                    }
                }

            }
            seller_store.product.variantUpdateContentChanges(selectedOption, saleOption);
            if (productSaleOptions.product_image_id !== null) {
                var index = $('.product-carousel-' + productId + ' .carousel-item[data-image-id="' + productSaleOptions.product_image_id + '"]').attr('data-index');
                $('#carousel-thumb').carousel(Number(index));
            }
        },

        variantUpdateContentChanges(data, saleOption) {
            var productStockElem = $(".product-stock-status"),
                addToCartElem = $('#add-to-cart'),
                itemQuantityElem = $('#item-quantity');
            $('.product-variant-price').text(data.variant_price);
            $('#product-variant-id').val(data.variant_id);
            $('#buy-now-cart').attr('data-variant-id', data.variant_id);
            $('.product-variant-compare-price small s').text(data.compare_price);
            if (data.is_in_stock) {
                productStockElem.html('<span class="green-text">IN STOCK</span>');
                addToCartElem.attr('disabled', false);
            } else if (data.is_backorder_stock) {
                productStockElem.html('<span class="green-text">BACKORDER</span>');
                addToCartElem.attr('disabled', false);
            } else {
                productStockElem.html('<span class="red-text">OUT OF STOCK</span>');
                addToCartElem.attr('disabled', true);
            }
            if (saleOption === 'subscription') {
                itemQuantityElem.val('1');
                itemQuantityElem.attr('disabled', true);
                $('#productDetails #subscriptionOptions').show();
                $('#productDetails #giftOptions').hide();
                $('#productDetails #quantity-wrapper .minus, #productDetails #quantity-wrapper .plus').attr('disabled', true);
            } else if (saleOption == 'gift') {
                itemQuantityElem.val('1');
                itemQuantityElem.attr('disabled', true);
                $('#productDetails #subscriptionOptions').hide();
                $('#productDetails #giftOptions').show();
                $('#productDetails #quantity-wrapper .minus, #productDetails #quantity-wrapper .plus').attr('disabled', true);
            } else {
                itemQuantityElem.attr('disabled', false);
                $('#productDetails #subscriptionOptions').hide();
                $('#productDetails #giftOptions').hide();
                $('#productDetails #quantity-wrapper .minus, #productDetails #quantity-wrapper .plus').attr('disabled', false);
            }
        },

        changeVariantCollection: function() {
            $('body').on('change', '.card .option-value-select', function () {
                seller_store.product.variantCollectionDataUpdate($(this).attr('data-product-id'));
            });
        },

        variantCollectionDataUpdate: function (productId) {
            var optionValueSelect = $('#collection_product_card_' + productId + ' select.option-value-select');
            if (optionValueSelect.length == 0) {
                seller_store.product.masterVariantCollectionUpdate(productId);
            } else {
                seller_store.product.singleVariantCollectionUpdate(optionValueSelect, productId);
            }
        },

        masterVariantCollectionUpdate: function (productId) {
            var selectedOption,
                saleOption = "single_purchase",
                productSaleOptions = window.productOptions[productId][saleOption][0];

            selectedOption = productSaleOptions;
            seller_store.product.variantCollectionUpdateContentChanges(selectedOption, saleOption, productId);
        },

        singleVariantCollectionUpdate: function (optionValueSelect, productId) {
            var option_value_ids = optionValueSelect.map(function () {
                    return Number($(this).val());
                }).get(),
                saleOption = "single_purchase",
                productSaleOptions = window.productOptions[productId][saleOption];
            for (_i = 0, _len = productSaleOptions.length; _i < _len; _i++) {
                var json = productSaleOptions[_i];
                if (seller_store.base.arraysEqual(json.option_value_ids, option_value_ids)) {
                    var selectedOption = json;
                    break;
                }
            }
            seller_store.product.variantCollectionUpdateContentChanges(selectedOption, saleOption, productId);
        },

        variantCollectionUpdateContentChanges(data, saleOption, productId) {
            var productStockElem = $("#collection_product_card_" + productId + " .product-stock-status"),
                addToCartElem = $("#collection_product_card_" + productId + " .add-to-cart-collection");
            $("#collection_product_card_" + productId + " .product-variant-price-collection").text(data.variant_price);
            $("#collection_product_card_" + productId + " .product-variant-id").val(data.variant_id);
            $("#collection_product_card_" + productId + " .product-variant-compare-price-collection").text(data.compare_price);
            if (data.is_in_stock) {
                productStockElem.html('<span class="green-text">IN STOCK</span>');
                addToCartElem.attr('disabled', false);
            } else if (data.is_backorder_stock) {
                productStockElem.html('<span class="green-text">BACKORDER</span>');
                addToCartElem.attr('disabled', false);
            } else {
                productStockElem.html('<span class="red-text">OUT OF STOCK</span>');
                addToCartElem.attr('disabled', true);
            }
        },


        buildVideos: function () {

            $(".carousel").on("slide.bs.carousel", function (e) {
                var prev = $(this)
                    .find(".active")
                    .index();
                var next = $(e.relatedTarget).index();
                var video = $("#video-player")[0];
                var videoSlide = $("#video-player")
                    .closest(".carousel-item")
                    .index();
                if (next === videoSlide) {
                    if (video.tagName == "IFRAME") {
                        player.playVideo();
                    } else {
                        seller_store.product.createVideo(video);
                    }
                } else {
                    if (typeof player !== "undefined") {
                        player.pauseVideo();
                    }
                }
            });
        },

        createVideo: function (video) {
            var youtubeScriptId = "youtube-api";
            var youtubeScript = document.getElementById(youtubeScriptId);
            var videoId = video.getAttribute("data-video-id");

            if (youtubeScript === null) {
                var tag = document.createElement("script");
                var firstScript = document.getElementsByTagName("script")[0];

                tag.src = "https://www.youtube.com/iframe_api";
                tag.id = youtubeScriptId;
                firstScript.parentNode.insertBefore(tag, firstScript);
            }

            window.onYouTubeIframeAPIReady = function () {
                window.player = new window.YT.Player(video, {
                    videoId: videoId,
                    playerVars: {
                        autoplay: 1,
                        modestbranding: 1,
                        rel: 0
                    }
                });
            };
        },

        loadDesign: function () {
            var productId = $('#productDetails').attr('data-product-id');
            $.ajax(
                {
                    url: '/products/' + productId + ' /live_content',
                    type: 'GET',
                    dataType: 'json',
                    success: function (data) {
                        if (data.live_content !== null) {
                            $('.product-content-design .is-wrapper').html(data.live_content.content);
                        } else {
                            $('.product-content-design .is-wrapper').html("");
                        }
                    }
                });
        },

        buildOnPageLoad: function () {
            var sale_options = $('#productDetails').data('sale-options').split(',');
            if (sale_options[0] == 'subscription' || sale_options[0] == 'gift') {
                $('#item-quantity').val('1');
                $('#item-quantity').attr('disabled', true);
            }
            seller_store.product.variantDataUpdate();
            seller_store.product.loadDesign();
        },

        buyNow: function () {
            $('body').on('click', '#buy-now-cart', function () {
                var productId = $(this).attr('data-product-id'),
                    variantId = $(this).attr('data-variant-id'),
                    quantity = $('#item-quantity').val(),
                    productSaleOptionCount = $('#productDetails').data('sale-option-count'),
                    saleOption = productSaleOptionCount > 1 ? $('input[name="cart_item[sale_option]"]:checked').val() : $('input[name="cart_item[sale_option]"]').val(),
                    planId = (saleOption === 'subscription') ? $('#cart_item_subscription').val() : $('#cart_item_gift').val();

                seller_store.base.assignLoadingModal("loadingAddItemCart", "Adding item to your cart...");
                seller_store.base.enableLoadingModal("#loadingAddItemCart", $('#myCart'));

                $('.buy-now-cart-flash').remove();

                $.ajax(
                    {
                        url: '/products/' + productId + '/variants/' + variantId + '/buy_now',
                        type: 'POST',
                        data: {cart_item: {quantity: quantity, plan_id: planId, sale_option: saleOption}},
                        dataType: 'json',
                        success: function (data) {
                            window.location = data.redirect_url;
                        },
                        error: function (xhr, evt, status) {
                            seller_store.base.addCustomFlash('#productDetails', 'danger buy-now-cart-flash', "Unable to buy this product now. Please try again or contact store support.");
                            seller_store.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }
