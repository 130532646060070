seller_store.discount =
    {
        displayDiscountForm: function () {
            $('body').on('click', '#open-discount-form', function () {
                $('#add-discount-form').show();
                $(this).hide();
                $('#discount_code').focus();
            })
        },

        addToCart: function () {
            $('body').on('submit', '#add-discount-form', function () {
                $('#updatingCartCheckout').modal('show');
                var url = $(this).attr('action'),
                    $this = $(this),
                    discountError = $("#discount-error");
                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            seller_store.checkout.updateCartTotals(data);
                            discountError.hide();
                            $('#updatingCartCheckout').modal('hide');
                            $('#discount_code').val('');
                        },
                        error: function (xhr, evt, status) {
                            var responseJson = $.parseJSON(xhr.responseText)
                            $('#updatingCartCheckout').modal('hide');
                            discountError.text(responseJson.errors[0]);
                            discountError.show();
                            if (responseJson.user_access)
                            {
                                $('#loginAccountCheckout').modal('show');
                            }
                        }
                    });
                return false;
            });
        }
    }