seller_store.checkout =
    {
        continueBilling: function () {
            $('body').on('click', '#continue-to-billing', function (event) {
                event.preventDefault();
                if (window.checkoutShippingValid === true) {
                    $('#billing-tab-header').click();
                    seller_store.base.scrollTop("main");
                }
                return false;
            });
        },

        continuePayment: function () {
            $('body').on('click', '#continue-to-payment', function (event) {
                event.preventDefault();
                if (window.checkoutBillingValid === true) {
                    $('#payment-tab-header').click();
                    seller_store.base.scrollTop("main");
                }
                return false;
            });
        },

        returnToShipping: function () {
            $('body').on('click', '#return-to-shipping', function () {
                $('#shipping-tab-header').click();
                seller_store.base.scrollTop("main");
                return false;
            });
        },

        updatedShippingCountry: function () {
            $('#delivery_address_country_id').change(function () {
                seller_store.checkout.updateShippingTax();
            });
        },

        updatedShippingZip: function () {
            var textInput = document.getElementById('delivery_address_zip'),
                timeout = null,
                taxjarStatus = $('meta[name=taxjar-status]').attr('content');

            if (typeof (textInput) != 'undefined' && textInput != null && taxjarStatus === 'enabled') {
                textInput.onkeyup = function (e) {

                    clearTimeout(timeout);

                    timeout = setTimeout(function () {
                        seller_store.checkout.updateShippingTax();
                    }, 500);
                };
            }
        },

        validateCheckoutEmail: function () {
            var textInput = document.getElementById('email');

            var timeout = null;

            if (typeof (textInput) != 'undefined' && textInput != null) {
                textInput.onkeyup = function (e) {

                    clearTimeout(timeout);

                    timeout = setTimeout(function () {
                        $.ajax(
                            {
                                url: '/carts/validate_email?email=' + textInput.value,
                                type: 'GET',
                                dataType: 'json',
                                success: function (data) {
                                    $('#email').val('');
                                    $('#loginAccountCheckout #user_email').val(data.email);
                                    $('#loginAccountCheckout').modal('show');
                                }
                            });
                    }, 500);
                };
            }
        },

        updateShippingTax: function () {
            $('#updatingCartCheckout').modal('show');
            var elemZip = $("#delivery_address_zip").val(),
                elemCountryId = $("#delivery_address_country_id").val();
            $.ajax(
                {
                    url: '/carts/shippings',
                    type: 'PATCH',
                    data: {'country_id': elemCountryId, 'zip': elemZip},
                    dataType: 'json',
                    success: function (data) {
                        seller_store.checkout.pollCheckoutShippingTaxValidation();
                    },
                    error: function (xhr, evt, status) {
                        seller_store.base.addFlash('danger', 'There was an error when updating your cart shipping. Please try again.');
                        $('#updatingCartCheckout').modal('hide');
                    }
                });
        },

        pollCheckoutShippingTaxValidation: function () {
            var checkoutShippingPollingInterval = window.setInterval(function () {
                $.ajax(
                    {
                        url: '/carts/shippings/validate',
                        type: 'GET',
                        dataType: 'json',
                        success: function (data) {
                            window.clearInterval(checkoutShippingPollingInterval);
                            seller_store.checkout.updateCheckoutSummary(data);
                            $('#country_alpha_2').val(data.country_alpha_2);
                            $('#updatingCartCheckout').modal('hide');
                        }
                    });
            }, 2000);
        },

        duplicateAddress: function () {
            $('.use-delivery-address').change(function () {
                if (this.checked) {
                    seller_store.checkout.triggerDeliveryBillingAddress();
                } else {
                    return $('input[data-field-name*="billing-"]').val('');
                }
            });
        },

        useDifferentBillingAddress: function () {
            $('.different-billing-address').change(function () {
                if (this.checked) {
                    window.copyShippingToBilling = false;
                    return $('input[data-field-name*="billing-"]').val('');
                } else {
                    window.copyShippingToBilling = true;
                    seller_store.checkout.triggerDeliveryBillingAddress();
                }
            });
        },

        triggerDeliveryBillingAddress: function () {
            $('.copy-delivery').each(function () {
                var fieldValue = $(this).val(),
                    fieldAttribute = $(this).attr('data-field-name');
                return $('[data-field-name="billing-' + fieldAttribute + '"]').val(fieldValue);
            });
        },

        termsDisablePlaceOrder: function () {
            $('#checkoutTerms').change(function () {
                if (this.checked) {
                    $('#place-order-button').removeAttr('disabled');
                } else {
                    $('#place-order-button').attr('disabled', 'true');
                }
            });
        },

        loginAccountCheckout: function () {
            $("body").on("submit", '#loginAccountCheckout #new_user', function () {
                var url = $(this).attr('action'),
                    $this = $(this);
                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            seller_store.base.disableCloseTabPrompt();
                            window.location.reload();
                        },
                        error: function (xhr, evt, status) {
                            $('#loginAccountCheckout .json-error-wrapper #errors ul').html("<li><i class='fa fa-times-circle'></i>Incorrect login details</li>");
                            $('#loginAccountCheckout .json-error-wrapper').show();
                        }
                    });
                return false;
            });
        },

        loginAccountOrderComplete: function () {
            $("body").on("submit", '#loginAccountOrderComplete #new_user', function () {
                var url = $(this).attr('action'),
                    $this = $(this);
                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#loginAccountOrderComplete').modal('hide');
                            $('#login-call-to-actions').remove();
                            window.location = data.redirect_url;
                        },
                        error: function (xhr, evt, status) {
                            $('#loginAccountOrderComplete .json-error-wrapper #errors ul').html("<li><i class='fa fa-times-circle'></i>Incorrect login details</li>");
                            $('#loginAccountOrderComplete .json-error-wrapper').show();
                        }
                    });
                return false;
            });
        },

        updateCartTotals: function (data) {
            var shippingType = $('#user_shipping_type').val();
            var total = shippingType === 'standard' ? data.total : data.pre_shipping_total;

            $('#subtotal').html(data.subtotal);
            $('#delivery').html(data.delivery);
            $('#tax').html(data.tax);
            $('#inc-tax').html(data.tax);
            $('#pre-discount-total').html(data.pre_discount_total);
            $('#total').attr('data-total', data.total).attr('data-pre-shipping-total', data.pre_shipping_total).html(total);
            $('#checkout-discounts').attr('data-free-shipping', data.has_free_shipping_discount).html(data.discounts);
        },

        updateCheckoutSummary: function (data) {
            seller_store.checkout.updateCartTotals(data);

            var i;
            for (i = data.cart_items.length - 1; i >= 0; i--) {
                var item = data.cart_items[i],
                    cartItemRow = $('.order-summary-cart-item[data-cart-item-id="' + item.id + '"]');
                if (item.has_valid_shipping) {
                    cartItemRow.removeClass('inactive');
                    cartItemRow.addClass('active');
                } else {
                    cartItemRow.removeClass('active');
                    cartItemRow.addClass('inactive');
                }
            }

            if (data.has_valid_shipping) {
                $('#checkout-delivery-country-warning').hide();
                $('#checkoutTerms').attr('disabled', false);
                $('#place-order-button').attr('disabled', false);
            } else {
                $('#checkout-delivery-country-warning').show();
                $('#checkoutTerms').attr('disabled', true).prop('checked', false);
                $('#place-order-button').attr('disabled', true);
            }
        },

        initCountdown: function (element) {
            "undefined" != typeof b && clearInterval(b);
            var b = setInterval(function () {
                var elem = $(element),
                    endTime = elem.attr('data-end-at');

                const minutesSpan = elem.find('.minutes');
                const secondsSpan = elem.find('.seconds');

                var t = seller_store.base.getTimeRemaining(endTime);

                if (t.total <= 0) {
                    clearInterval(b);
                    $(element).parent().html('An item you ordered is in high demand. Order reservation ended.')
                }

                minutesSpan.html(('0' + t.minutes).slice(-2));
                secondsSpan.html(('0' + t.seconds).slice(-2));
            }, 1e3);
        },

        isStandardShipping: function () {
            return $('#user_shipping_type').val() === 'standard' ? true : false;
        },

        isPickUpShipping: function () {
            return $('#user_shipping_type').val() === 'pick_up' ? true : false;
        },

        validStandardShipping: function()
        {
            return seller_store.checkout.isStandardShipping() && seller_store.base.inputHasValue($('#email')) &&
                seller_store.base.inputHasValue($('#delivery_address_first_name')) &&
                seller_store.base.inputHasValue($('#delivery_address_last_name')) &&
                seller_store.base.inputHasValue($('#delivery_address_address')) &&
                seller_store.base.inputHasValue($('#delivery_address_city')) &&
                seller_store.base.inputHasValue($('#delivery_address_county')) &&
                seller_store.base.inputHasValue($('#delivery_address_zip')) &&
                seller_store.base.inputHasValue($('#delivery_address_country_id'))
        },

        validPickUpShipping: function()
        {
            return seller_store.checkout.isPickUpShipping() && seller_store.base.inputHasValue($('#email')) &&
                seller_store.base.inputHasValue($('#pick_up_delivery_address_first_name')) &&
                seller_store.base.inputHasValue($('#pick_up_delivery_address_last_name')) &&
                seller_store.base.inputHasValue($('#pick_up_delivery_address_phone')) &&
                seller_store.base.inputHasValue($('#pick-up-select'))
        },

        validShippingTab: function () {
            window.copyShippingToBilling = true;
            window.setInterval(function () {
                if (seller_store.checkout.validStandardShipping()) {
                    window.checkoutShippingValid = true;
                    $('#billing-tab-header').removeClass('disabled');
                    $('#shipping-tab-header i').addClass('active');
                    if (window.copyShippingToBilling === true) {
                        $('.use-delivery-address').attr('checked', true);
                        seller_store.checkout.triggerDeliveryBillingAddress();
                    }
                } else if (seller_store.checkout.validPickUpShipping()) {
                    window.checkoutShippingValid = true;
                    $('#billing-tab-header').removeClass('disabled');
                    $('#shipping-tab-header i').addClass('active');
                } else {
                    window.checkoutShippingValid = false;
                    $('#billing-tab-header').addClass('disabled');
                    $('#shipping-tab-header i').removeClass('active');
                }
            }, 1000);
        },

        validBillingTab: function () {
            window.setInterval(function () {
                if (seller_store.base.inputHasValue($('#billing_address_first_name')) && seller_store.base.inputHasValue($('#billing_address_last_name'))
                    && seller_store.base.inputHasValue($('#billing_address_address')) && seller_store.base.inputHasValue($('#billing_address_city')) &&
                    seller_store.base.inputHasValue($('#billing_address_county')) && seller_store.base.inputHasValue($('#billing_address_zip')) &&
                    seller_store.base.inputHasValue($('#billing_address_country_id'))) {
                    window.checkoutBillingValid = true;
                    $('#payment-tab-header').removeClass('disabled');
                    $('#billing-tab-header i').addClass('active');
                } else {
                    window.checkoutBillingValid = false;
                    $('#payment-tab-header').addClass('disabled');
                    $('#billing-tab-header i').removeClass('active');
                }
            }, 1000);
        },

        shippingTypeTabs: function () {
            $('#pick-up-shipping-tab').on('click', function () {
                $('#summary-shipping-row').hide();
                $('#total').text($('#total').attr('data-pre-shipping-total'));
                $('#user_shipping_type').val('pick_up');
                $('#discount_shipping_type').val('pick_up');
                $('#delivery-address-radio').hide();
                $('#billing-address-radio').hide();
                seller_store.pick_up.disablePickUpAddress(false);
                seller_store.pick_up.disableStandardAddress(true);
                $('#checkout-discounts .free-shipping').hide();
            });
            $('#standard-shipping-tab').on('click', function () {
                $('#summary-shipping-row').show();
                $('#total').text($('#total').attr('data-total'));
                $('#user_shipping_type').val('standard');
                $('#discount_shipping_type').val('standard');
                $('#delivery-address-radio').show();
                $('#billing-address-radio').show();
                seller_store.pick_up.disableStandardAddress(false);
                seller_store.pick_up.disablePickUpAddress(true);
                $('#checkout-discounts .free-shipping').show();
            });
        }
    }
