seller_store.review =
    {
        add: function () {
            $('body').on('click', '#review-product', function () {
                seller_store.review.addReview($(this), undefined);
                return false;
            });
        },

        addReview: function (reviewEmailId, resourceType, resourceId) {
            var productId = $('#productDetails').attr('data-product-id'),
                url = reviewEmailId === undefined ? '/products/' + productId + '/reviews/new' :
                    '/products/' + productId + '/reviews/new?review_email_id=' + reviewEmailId + '&resource_type=' + resourceType + '&resource_id=' + resourceId;

            seller_store.base.assignLoadingModal("loadingReviewProduct", "Reviewing product...");
            seller_store.base.enableLoadingModal("#loadingReviewProduct", $('#reviewProductModal'));

            $('.product-review-flash').remove();

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#product-review-modal').html(data.modal);
                        seller_store.base.openModal('#reviewProductModal');
                        var $rateYo = $("#star-rating-animation").rateYo(
                            {
                                fullStar: true,
                                rating: 5
                            });
                        $rateYo.on("rateyo.set", function (e, data) {
                            document.getElementById("review_rating").value = data.rating;
                        });
                        seller_store.base.disableLoadingModal("#loadingReviewProduct", $('#reviewProductModal'));
                    },
                    error: function (xhr, evt, status) {
                        seller_store.base.addCustomFlash("#productDetails", 'danger product-review-flash', 'Unable to add a review at the moment, please try again later');
                        seller_store.base.scrollTop('main');
                        seller_store.base.disableLoadingModal("#loadingReviewProduct", $('#reviewProductModal'));
                    }
                });
        },

        create: function () {
            $('body').on('submit', '#create-product-review', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_store.base.assignLoadingModal("loadingReviewProduct", "Reviewing product...");
                seller_store.base.enableLoadingModal("#loadingReviewProduct", $('#reviewProductModal'));

                $('.product-review-flash').remove();

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#reviewProductModal').modal('hide');
                            $('#review-product').remove();
                            seller_store.base.addCustomFlash("#productDetails", 'success product-review-flash', 'Successfully submitted your review for approval.');
                            seller_store.base.scrollTop('main');
                            seller_store.base.disableLoadingModal("#loadingReviewProduct", $('#reviewProductModal'));
                        },
                        error: function (xhr, evt, status) {
                            seller_store.base.jsonErrors(xhr, evt, status, $this);
                            seller_store.base.disableLoadingModal("#loadingReviewProduct", $('#reviewProductModal'));
                        }
                    });
                return false;
            });
        },

        triggerEmailReview: function () {
            if (seller_store.base.getUrlVars()['email_review'] !== undefined) {
                if ($('meta[name="product-reviewable"]').attr('content') === 'true') {
                    seller_store.review.addReview(seller_store.base.getUrlVars()['review_email_id'],
                        seller_store.base.getUrlVars()['resource_type'],
                        seller_store.base.getUrlVars()['resource_id']
                    );
                } else {
                    if ($('meta[name="user-signed-in"]').attr('content') === 'true') {
                        seller_store.base.addCustomFlash("#productDetails", 'success', 'You have already left a review for this product.');
                        seller_store.base.scrollTop('main');
                    } else {
                        seller_store.base.addCustomFlash("#productDetails", 'success', 'Please login to review this product.');
                        seller_store.base.scrollTop('main');
                    }
                }
            }
        },

        termsDisableSubmission: function () {
            $('body').on('change', '#reviewTerms', function () {
                if (this.checked) {
                    $('#submit-review').removeAttr('disabled');
                } else {
                    $('#submit-review').attr('disabled', 'true');
                }
            });
        }
    }