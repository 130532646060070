var $formElem = $('.upsell-product-tile');
if ($formElem.length > 0) {
    var productIds = [];
    $.each($formElem, function () {
        productIds.push($(this).attr('data-product-id'));
    });
    $.ajax(
        {
            url: '/upsells/delivery_prices',
            type: 'POST',
            data: {product_ids: productIds},
            dataType: 'json',
            success: function (data) {
                for (var i = 0; i < data.products.length; i++) {
                    var obj = data.products[i];

                    $('#product_' + obj.uid + ' .product-delivery-price small span').text(obj.delivery_price);
                }
            },
            error: function (xhr, evt, status) {
            }
        });
}