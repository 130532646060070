$(document).ready(function () {
    var $formElem = $('#purchase-upsell-form');
    if ($formElem.length > 0) {
        var paymentMethodId = seller_store.base.getURLParameter('payment_method_id');
        $('body').on('submit', '#purchase-upsell-form', function () {
            $('#upsell-validation-warning').remove();
            var $this = $(this),
                url = $this.attr('action');
            $('#loadingUpsell').modal('show');

            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    data: $this.serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $.ajax(
                            {
                                url: '/upsells/confirm_order',
                                type: 'POST',
                                data: {payment_method_id: paymentMethodId, cart_item_id: data.cart_item_id},
                                dataType: 'json',
                                success: function (data) {
                                    handleUpsellOrderServerResponse(data, data.payment_method_id);
                                }
                            });
                    }
                });
            return false;
        });
    }
});

function handleUpsellOrderServerResponse(response, paymentMethodId) {
    var stripe = Stripe($('meta[name="stripe-key"]').attr('content'), {stripeAccount: $('meta[name="stripe-account-id"]').attr('content')});
    if (response.requires_action) {
        stripe.handleCardAction(
            response.payment_intent_client_secret
        ).then(function (result) {
            if (result.error) {
                failed3dSecure(result.error.payment_intent.id, paymentMethodId);
                $('<div class="mt-4 alert alert-danger" role="alert" id="upsell-validation-warning">Your upsell purchase was unsuccessful. Please try again.</div>').insertBefore('#upsell-section');
                $('#loadingUpsell').modal('hide');
            } else {
                $.ajax(
                    {
                        url: '/upsells/confirm_order',
                        type: 'POST',
                        data: {payment_intent_id: result.paymentIntent.id},
                        dataType: 'json',
                        success: function (data) {
                            handleUpsellOrderServerResponse(data, paymentMethodId);
                        }
                    });
            }
        });
    } else {
        $('.card-body#product_' + response.product_id + ' .purchase-wrapper').html('<div class="h3 mb-3"><span class="badge badge-success">PURCHASED</span></div>');
        $('#loadingUpsell').modal('hide');
    }
}

function failed3dSecure(intentId) {
    $.ajax(
        {
            url: '/upsells/failed_3d_secure',
            type: 'POST',
            data: {payment_intent_id: intentId},
            dataType: 'json',
            success: function (data) {

            }
        });
}
