seller_store.cart =
    {
        buildOnPageLoad: function () {
            seller_store.cart.loadCart();
        },

        loadCart: function () {
            var cartId = $('#cart-item-list').attr('data-cart-id');

            $.ajax(
                {
                    url: '/carts/' + cartId,
                    type: 'GET',
                    dataType: 'json',
                    success: function (data) {
                        $('.cart-item-count').text(data.cart_item_count);
                        $('#cart-item-list').html(data.cart_items);
                        $('#cart-subtotal span:last-child').text(data.subtotal_pre_discount);
                        seller_store.cart.freeShippingNotification(data);
                    }
                });
        },

        addCartItem: function () {
            $('body').on('submit', '.add-to-cart-form', function () {
                var $this = $(this),
                    url = $this.attr('action'),
                    cartModal = $('#myCart');

                seller_store.base.assignLoadingModal("loadingAddItemCart", "Adding item to your cart...");
                seller_store.base.enableLoadingModal("#loadingAddItemCart", $('#myCart'));

                $('.add-to-cart-flash').remove();

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#cart-subtotal span:last-child').text(data.subtotal_pre_discount);
                            $('.navbar .cart-item-count').text(data.cart_item_count);
                            var cartItem = $('ul#cart-item-list li#cart-item-' + data.cart_item_id);
                            if (cartItem.length > 0) {
                                cartItem.replaceWith(data.cart_item);
                            } else {
                                $('ul#cart-item-list').append(data.cart_item);
                            }
                            if (!cartModal.hasClass('show')) {
                                cartModal.modal('show');
                            }
                            if (data.subscription_cart_item === true) {
                                $('ul#cart-item-list li#cart-item-' + data.cart_item_id + ' .decrease-quantity').attr('disabled', true);
                                $('ul#cart-item-list li#cart-item-' + data.cart_item_id + ' .increase-quantity').attr('disabled', true);
                                $('ul#cart-item-list li#cart-item-' + data.cart_item_id + ' .cart-item-quantity').attr('disabled', true);
                            }
                            seller_store.cart.freeShippingNotification(data);
                            seller_store.base.addCustomFlash('#myCart .modal-body', 'success add-to-cart-flash', "<i class='fa fa-check'></i> An item was added to the cart!");
                            seller_store.base.disableLoadingModal("#loadingAddItemCart", $('#myCart'));
                        },
                        error: function (xhr, evt, status) {
                            if (!cartModal.hasClass('show')) {
                                cartModal.modal('show');
                            }
                            seller_store.base.disableLoadingModal("#loadingAddItemCart", $('#myCart'));
                            seller_store.base.addCustomFlash('#myCart .modal-body', 'danger add-to-cart-flash', "Unable to add item to your cart. Please try again or contact store support.");
                            seller_store.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        increaseQuantity: function () {
            $('body').on('click', '.increase-quantity', function () {
                var $this = $(this),
                    cartId = $('ul#cart-item-list').attr('data-cart-id'),
                    cartItemId = $this.attr('data-cart-item-id');

                seller_store.base.assignLoadingModal("loadingIncrementCartItemQuantity", "Updating your cart quantity...");
                seller_store.base.enableLoadingModal("#loadingIncrementCartItemQuantity", $('#myCart'));

                $('.add-to-cart-flash').remove();

                $.ajax(
                    {
                        url: '/carts/' + cartId + '/cart_items/' + cartItemId + '/increase_quantity',
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('ul#cart-item-list li#cart-item-' + cartItemId + ' .quantity').val(data.quantity);
                            $('ul#cart-item-list li#cart-item-' + cartItemId + ' .product-price').text(data.total_price);
                            $('#cart-subtotal span:last-child').text(data.subtotal_pre_discount);
                            $('.cart-item-count').text(data.total_quantity);
                            seller_store.cart.freeShippingNotification(data);
                            seller_store.base.disableLoadingModal("#loadingIncrementCartItemQuantity", $('#myCart'));
                            seller_store.base.addCustomFlash('#myCart .modal-body', 'success add-to-cart-flash', "Successfully updated your cart quantity.");
                        },
                        error: function (xhr, evt, status) {
                            var errorMessage = $.parseJSON(xhr.responseText).errors[0],
                                formattedErrorMessage = errorMessage === undefined ? "Failed to update cart item, please try again." : errorMessage;
                            seller_store.base.disableLoadingModal("#loadingIncrementCartItemQuantity", $('#myCart'));
                            seller_store.base.addCustomFlash('#myCart .modal-body', 'danger add-to-cart-flash', formattedErrorMessage);
                            seller_store.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        decreaseQuantity: function () {
            $('body').on('click', '.decrease-quantity', function () {
                var $this = $(this),
                    cartId = $('ul#cart-item-list').attr('data-cart-id'),
                    cartItemId = $this.attr('data-cart-item-id');

                seller_store.base.assignLoadingModal("loadingDecrementCartItemQuantity", "Updating your cart quantity...");
                seller_store.base.enableLoadingModal("#loadingDecrementCartItemQuantity", $('#myCart'));

                $('.add-to-cart-flash').remove();

                $.ajax(
                    {
                        url: '/carts/' + cartId + '/cart_items/' + cartItemId + '/decrease_quantity',
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('ul#cart-item-list li#cart-item-' + cartItemId + ' .quantity').val(data.quantity);
                            $('ul#cart-item-list li#cart-item-' + cartItemId + ' .product-price').text(data.total_price);
                            $('#cart-subtotal span:last-child').text(data.subtotal_pre_discount);
                            $('.cart-item-count').text(data.total_quantity);
                            seller_store.cart.freeShippingNotification(data);
                            seller_store.base.disableLoadingModal("#loadingDecrementCartItemQuantity", $('#myCart'));
                            seller_store.base.addCustomFlash('#myCart .modal-body', 'success add-to-cart-flash', "Successfully updated your cart quantity.");
                        },
                        error: function (xhr, evt, status) {
                            var errorMessage = $.parseJSON(xhr.responseText).errors[0],
                                formattedErrorMessage = errorMessage === undefined ? "Failed to update cart item, please try again." : errorMessage;
                            seller_store.base.disableLoadingModal("#loadingDecrementCartItemQuantity", $('#myCart'));
                            seller_store.base.addCustomFlash('#myCart .modal-body', 'danger add-to-cart-flash', formattedErrorMessage);
                            seller_store.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        removeCartItem: function () {
            $('body').on('click', '.remove-cart-item', function () {
                var $this = $(this),
                    cartId = $('ul#cart-item-list').attr('data-cart-id'),
                    cartItemId = $this.attr('data-cart-item-id');

                seller_store.base.assignLoadingModal("loadingRemoveItemCart", "Removing item from your cart...");
                seller_store.base.enableLoadingModal("#loadingRemoveItemCart", $('#myCart'));

                $('.add-to-cart-flash').remove();

                $.ajax(
                    {
                        url: '/carts/' + cartId + '/cart_items/' + cartItemId,
                        type: 'DELETE',
                        dataType: 'json',
                        success: function (data) {
                            $('ul#cart-item-list li#cart-item-' + cartItemId).remove();
                            $('#cart-subtotal span:last-child').text(data.subtotal_pre_discount);
                            $('.cart-item-count').text(data.total_quantity);
                            seller_store.cart.freeShippingNotification(data);
                            seller_store.base.addCustomFlash('#myCart .modal-body', 'success add-to-cart-flash', "Successfully removed an item from your cart.");
                            seller_store.base.disableLoadingModal("#loadingRemoveItemCart", $('#myCart'));
                        },
                        error: function (xhr, evt, status) {
                            seller_store.base.disableLoadingModal("#loadingRemoveItemCart", $('#myCart'));
                            seller_store.base.addCustomFlash('#myCart .modal-body', 'danger add-to-cart-flash', "Failed to remove cart item, please try again.");
                            seller_store.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        newItemFlash: function () {
            var newCartItem = seller_store.base.getURLParameter('new_cart_item');
            if (newCartItem === 'true') {
                seller_store.base.addFlash('success', "An item was added to your cart!")
            }
        },

        loadReorderCart: function()
        {
            if (seller_store.base.getUrlVars()["reordercart"] === "true")
            {
                $('#myCart').modal('show');
            }
        },

        freeShippingNotification: function(data)
        {
            if (data.has_free_shipping_discount === true)
            {
                $('.cart-list-free-shipping').show();
            } else {
                $('.cart-list-free-shipping').hide();
            }
            if (data.has_next_free_shipping === true)
            {
                $('.cart-list-available-shipping span').text(data.next_free_shipping_value);
                $('.cart-list-available-shipping').show();
            } else {
                $('.cart-list-available-shipping').hide();
            }
        }
    }
