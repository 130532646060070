seller_store.collection = {

    buildOnPageLoad: function()
    {
       $('.card select.option-value-select').each(function()
       {
           seller_store.product.variantCollectionDataUpdate($(this).attr('data-product-id'));
       })
    },

    updateTagNameCheckboxes: function()
    {
        $('.product-tag-checkbox').click(function() {
            var tagNames = [];
            $.each($(".product-tag-checkbox input:checked"), function () {
                tagNames.push($(this).val());
            });
            $('#tag_names').val(tagNames.join(','));
        });
        $('.mobile-product-tag-checkbox').click(function() {
            var tagNames = [];
            $.each($(".mobile-product-tag-checkbox input:checked"), function () {
                tagNames.push($(this).val());
            });
            $('#mobile_tag_names').val(tagNames.join(','));
        });
    },

    updateOptionTypeCheckboxes: function() {
        $('.option-type-checkbox').click(function() {
            var optionNames = [];
            $.each($(".option-type-checkbox input:checked"), function () {
                optionNames.push($(this).val());
            });
            $('#option_value_names').val(optionNames.join(','));
        });
        $('.mobile-option-type-checkbox').click(function() {
            var optionNames = [];
            $.each($(".mobile-option-type-checkbox input:checked"), function () {
                optionNames.push($(this).val());
            });
            $('#mobile_option_value_names').val(optionNames.join(','));
        });
    }
}