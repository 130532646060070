seller_store.base =
    {
        initImageLightbox: function () {
            $("#mdb-lightbox-ui").load("/mdb-addons/mdb-lightbox-ui.html");
        },

        mdbootstrap: function () {
            $('.mdb-select').materialSelect();
            // SideNav Button Initialization
            $(".button-collapse").sideNav();
            // SideNav Scrollbar Initialization
            var sideNavScrollbar = document.querySelector('.custom-scrollbar');
            if (sideNavScrollbar != null) {
                var ps = new PerfectScrollbar(sideNavScrollbar);
            }
            // fix for error wrapping on form text field labels
            $('.field_with_errors + label').addClass('active');
            // fix for double click dropdown arrow
            $('.mdb-select').click(e => e.stopPropagation());

            var announceBar = $('meta[name=announcement-bar]').attr('content');
            $(window).on('load', function () {
                // 44px height from the announcement bar
                var navbarHeight = $('nav.navbar').outerHeight(),
                    announceBarHeight = $('#announcement-bar').outerHeight(),
                    paddingtop = announceBar === 'enabled' ? (navbarHeight + announceBarHeight) : navbarHeight
                $('.announcement-bar-nav').css('margin-top', announceBarHeight);
                $('main').css('padding-top', paddingtop);
            });

            $(function () {
                $('[data-toggle="tooltip"]').tooltip()
            });
        },

        jsonErrors: function (xhr, evt, status, form) {
            var content, value, _i, _len, _ref, $this, contentWrapper;
            $this = form;
            contentWrapper = $this.find('.json-error-wrapper');
            content = contentWrapper.find('#errors');
            content.find('ul').empty();
            _ref = $.parseJSON(xhr.responseText).errors;
            // Append errors to list on page
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                value = _ref[_i];
                content.show().find('ul').append('<li><i class="fa fa-times-circle"></i>' + value + '</li>');
            }
            contentWrapper.show();
        },

        openModal: function (target) {
            $(target).modal(
                {
                    backdrop: 'static',
                    keyboard: false
                });
        },

        enableCloseTabPrompt: function () {
            window.onbeforeunload = function (e) {
                e = e || window.event;

                // For IE and Firefox prior to version 4
                if (e) {
                    e.returnValue = 'Sure?';
                }

                // For Safari
                return 'Sure?';
            }
        },

        disableCloseTabPrompt: function () {
            window.onbeforeunload = null;
        },

        getURLParameter: function (name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
        },

        resizeProductListImages: function () {
            $(".product-list-image-container").each(function () {
                var picture = $(this).find(".img-fluid"),
                    a = picture.width() / picture.height(),
                    b = $(this).width() / $(this).height();

                if (a < b) {
                    picture.css("height", "100%");
                    picture.css("width", "auto");
                } else {
                    var myMargin = ($(this).height() - $(this).width() / a) / 2;
                    picture.css("margin-top", myMargin);
                }
            });
        },

        scrollTop: function (element) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(element).offset().top
            }, 1000);
        },

        addFlash: function (alertClass, message) {
            $('#seller-store-wrapper').prepend('<div class="mt-4 alert alert-' + alertClass + '" role="alert">' + message + '</div>');
        },

        addCustomFlash: function (element, alertClass, message) {
            $(element).prepend('<div class="alert alert-' + alertClass + '" role="alert">' + message + '</div>');
        },

        loadingModal: function (modalId, message) {
            return '<div class="modal" id="' + modalId + '" tabindex="-1" role="dialog" aria-labelledby="loadingMessageLabel" aria-hidden="true" data-backdrop="static"><div class="modal-dialog modal-notify modal-primary" role="document"><div class="modal-content"><div class="modal-body"><h4 class="h4-responsive black-text font-weight-bold mt-2 mb-5 text-center"><strong>' + message + '</strong></h4><div class="d-flex justify-content-center mb-4"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div></div></div></div></div>'
        },

        assignLoadingModal: function (modalId, message) {
            $('#seller-store-loading-modal').html(seller_store.base.loadingModal(modalId, message));
        },

        enableLoadingModal: function (modalId, wrapper) {
            var loadingElem = $(modalId);

            loadingElem.modal('show');
            wrapper.find('input[type=submit]').attr('disabled', true);
            wrapper.find('.modal-content-overlay').show();
        },

        disableLoadingModal: function (modalId, wrapper) {
            var loadingElem = $(modalId);
            wrapper.find('input[type=submit]').attr('disabled', false);
            wrapper.find('.modal-content-overlay').hide();
            loadingElem.modal('hide');
        },

        getTimeRemaining: function (endTime) {
            const total = Date.parse(endTime) - Date.parse(new Date());
            const seconds = Math.floor((total / 1000) % 60);
            const minutes = Math.floor((total / 1000 / 60) % 60);
            const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
            const days = Math.floor(total / (1000 * 60 * 60 * 24));

            return {
                total,
                days,
                hours,
                minutes,
                seconds
            }
        },

        inputHasValue: function (element) {
            return element.length > 0 && (element.val() !== '' && element.val() !== null) ? true : false
        },

        arraysEqual: function (a, b) {
            if (a === b) return true;
            if (a == null || b == null) return false;
            if (a.length !== b.length) return false;

            a.sort();
            b.sort();

            for (var i = 0; i < a.length; ++i) {
                if (a[i] !== b[i]) return false;
            }
            return true;
        },

        initImageLazyLoad: function () {
            window.lazyLoadInstance = new LazyLoad({
                elements_selector: ".lazy"
            });
        },

        datePicker: function () {
            var dateFormat = $('meta[name=date-format]').attr('content');

            $('.datepicker').pickadate({
                clear: 'Clear selection',
                close: 'Cancel',
                format: dateFormat,
                formatSubmit: 'yyyy-mm-dd',
                hiddenName: true,
            })
        },

        getUrlVars: function () {
            var vars = {};
            var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
                vars[key] = value;
            });
            return vars;
        },

        updateQueryStringParameter: function (uri, key, value) {
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf('?') !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2');
            } else {
                return uri + separator + key + "=" + value;
            }
        },

        priceRangeSlider: function (element_id) {
            $(element_id + ' input').mdbRange({
                value: {
                    min: 0,
                    max: 100
                },
                single: {
                    active: true,
                    multi: {
                        active: true,
                        rangeLength: 1,
                    }
                }
            });

            var countingTargets = ['.priceFrom', '.priceTo'],
                countingText = [".priceFromText", '.priceToText'];

            $(element_id + '.multi-range-field > input').each(function (index, el) {
                $(el).on('change', function (e) {
                    e.preventDefault();

                    $(countingTargets[index]).val(e.target.value);
                    $(countingText[index]).text("$" + e.target.value);
                })
            })
        },

        verticalAlignContent: function (element_id) {
            $(function () {
                $(window).on('resize', function resize() {
                    $(window).off('resize', resize);
                    setTimeout(function () {
                        var content = $('#' + element_id),
                            top = (window.innerHeight - content.height()) / 2;
                        content.css('top', Math.max(0, top) - 90 + 'px');
                        $(window).on('resize', resize);
                    }, 50);
                }).resize();
            });
        }
    }
