seller_store.abandoned_cart =
    {
        active: function () {
            var cartAbandonment = $('meta[name=cart-abandonment]').attr('content');
            return cartAbandonment === "enabled" ? true : false
        },

        updateEmail: function () {
            if (seller_store.abandoned_cart.active()) {
                var textInput = document.getElementById('email'),
                    timeout = null;

                if (typeof (textInput) != 'undefined' && textInput != null) {
                    textInput.onkeyup = function (e) {

                        clearTimeout(timeout);

                        timeout = setTimeout(function () {
                            $.ajax(
                                {
                                    url: '/carts/abandoned_cart_email?email=' + textInput.value,
                                    type: 'POST',
                                    dataType: 'json',
                                    success: function (data) {
                                    }
                                });
                        }, 1000);
                    };
                }
            }
        }
    }