window.jQuery = $;
window.$ = $;

require("@rails/ujs").start();
require("jquery-ui-dist/jquery-ui");

require("moment-timezone");
require("underscore");

require("mdbootstrap/popper.min");
require("mdbootstrap/bootstrap");
require("mdbootstrap/mdb");
require("mdbootstrap/addons/multi-range");

require("seller/store/seller.stripe-checkout");
require("seller/store/seller.upsell-checkout");
require("seller/store/seller.upsell-delivery-prices");

require("seller/store/_seller");
require("seller/store/seller.base");
require("seller/store/seller.product");
require("seller/store/seller.checkout");
require("seller/store/seller.upsell");
require("seller/store/seller.cart");
require("seller/store/seller.discount");
require("seller/store/seller.abandoned_cart");
require("seller/store/seller.pick_up");
require("seller/store/seller.collection");
require("seller/store/seller.search");
require("seller/store/seller.order_bump");
require("seller/store/seller.review");
require("seller/store/seller.ready");