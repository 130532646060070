$(document).ready(function () {
    var $formElem = $('#process_order');
    if ($formElem.length > 0) {
        var stripe = Stripe($('meta[name="stripe-key"]').attr('content'), {stripeAccount: $('meta[name="stripe-account-id"]').attr('content')});
        var elements = stripe.elements();
        var style = {
            base: {
                color: '#32325d',
                lineHeight: '24px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };
        var card = elements.create('card', {style: style, hidePostalCode: true});
        card.mount('#card-element');
        card.addEventListener('change', function (event) {
            if (event.error) {
                $('#card-errors').text(event.error.message);
            } else {
                $('#card-errors').text('')
            }
        });

        $('body').on('submit', '#process_order', function () {
            $('#checkout-validation-warning').remove();
            $('button[type=submit]').attr('disabled', true);
            var billing_details = {
                name: document.getElementById('billing_address_first_name').value + ' ' + document.getElementById('billing_address_last_name').value,
                address: {
                    line1: document.getElementById('billing_address_address').value,
                    city: document.getElementById('billing_address_city').value,
                    postal_code: document.getElementById('billing_address_zip').value,
                    country: document.getElementById('country_alpha_2').value
                }
            };
            stripe.createPaymentMethod('card', card, {billing_details}).then(function (result) {
                if (result.error) {
                    $('#card-errors').text(result.error.message);
                    $('button[type=submit]').attr('disabled', false);
                } else {
                    $('#loadingCheckout').modal('show');
                    $('#checkout-payment-method-id').val(result.paymentMethod.id);
                    submitCheckoutForm(result);
                }
            });
            return false;
        });
    }
});

function submitCheckoutForm(result) {
    var $form = $('#process_order');

    $.ajax(
        {
            url: '/carts/confirm',
            type: 'POST',
            data: $form.serialize(),
            dataType: 'json',
            success: function (data) {
                if (data.has_shop_items) {
                    confirmShopOrder(result.paymentMethod.id, data.subscription_item_ids, data.gift_item_ids);
                } else if (data.subscription_item_ids > 0) {
                    confirmSubscriptionOrder(result.paymentMethod.id, data.subscription_item_ids, data.subscription_item_ids[0], data.gift_item_ids);
                } else {
                    confirmGiftOrder(result.paymentMethod.id, data.gift_item_ids, data.gift_item_ids[0])
                }
            },
            error: function (xhr, evt, status) {
                var errors = $.parseJSON(xhr.responseText).errors;
                $('<div class="mt-4 alert alert-danger" role="alert" id="checkout-validation-warning">Your order was unsuccessful. Please make sure you have filled out all required fields in the checkout form.<ul></ul></div>').insertBefore('#checkout-section');
                for (_i = 0, _len = errors.length; _i < _len; _i++) {
                    var value = errors[_i];
                    $('#checkout-validation-warning').find('ul').append('<li>' + value + '</li>');
                }
                $('button[type=submit]').attr('disabled', false);
                $('#loadingCheckout').modal('hide');
            }
        });
}

function confirmShopOrder(paymentMethodId, subscriptionCartItems, giftCartItems) {
    $('#loadingCheckout h4').text('Sit tight, we’re processing your one-time product order...');

    $.ajax(
        {
            url: '/carts/confirm_shop_order',
            type: 'POST',
            data: {payment_method_id: paymentMethodId},
            dataType: 'json',
            success: function (data) {
                handleShopOrderServerResponse(data, paymentMethodId, subscriptionCartItems, giftCartItems);
            }
        });
}

function confirmSubscriptionOrder(paymentMethodId, subscriptionCartItems, cartItemId, giftCartItems) {
    $('#loadingCheckout h4').text('Sit tight, we’re processing your subscription order...');
    $.ajax(
        {
            url: '/carts/confirm_subscription_order',
            type: 'POST',
            data: {payment_method_id: paymentMethodId, cart_item_id: cartItemId},
            dataType: 'json',
            success: function (data) {
                handleSubscriptionOrdersServerResponse(data, paymentMethodId, subscriptionCartItems, cartItemId, giftCartItems);
            }
        });
}

function confirmGiftOrder(paymentMethodId, giftCartItems, cartItemId) {
    $('#loadingCheckout h4').text('Sit tight, we’re processing your gift order...');
    $.ajax(
        {
            url: '/carts/confirm_gift_order',
            type: 'POST',
            data: {payment_method_id: paymentMethodId, cart_item_id: cartItemId},
            dataType: 'json',
            success: function (data) {
                handleGiftOrdersServerResponse(data, paymentMethodId, giftCartItems, cartItemId);
            }
        });
}

function handleShopOrderServerResponse(response, paymentMethodId, subscriptionCartItems, giftCartItems) {
    var stripe = Stripe($('meta[name="stripe-key"]').attr('content'), {stripeAccount: $('meta[name="stripe-account-id"]').attr('content')});
    if (response.requires_action) {
        stripe.handleCardAction(
            response.payment_intent_client_secret
        ).then(function (result) {
            if (result.error) {
                failed3dSecureShop(result.error.payment_intent.id, paymentMethodId);
            } else {
                $.ajax(
                    {
                        url: '/carts/confirm_shop_order',
                        type: 'POST',
                        data: {payment_intent_id: result.paymentIntent.id},
                        dataType: 'json',
                        success: function (data) {
                            handleShopOrderServerResponse(data, paymentMethodId, data.subscription_item_ids, data.gift_item_ids);
                        }
                    });
            }
        });
    } else {
        if (subscriptionCartItems.length > 0) {
            confirmSubscriptionOrder(paymentMethodId, subscriptionCartItems, subscriptionCartItems[0], giftCartItems);
        } else if (giftCartItems.length > 0) {
            confirmGiftOrder(paymentMethodId, giftCartItems, giftCartItems[0])
        } else {
            submitFinalForm(paymentMethodId);
        }
    }
}

function handleSubscriptionOrdersServerResponse(response, paymentMethodId, subscriptionCartItems, cartItemId, giftCartItems) {
    var stripe = Stripe($('meta[name="stripe-key"]').attr('content'), {stripeAccount: $('meta[name="stripe-account-id"]').attr('content')});
    if (response.requires_action) {
        stripe.handleCardAction(
            response.payment_intent_client_secret
        ).then(function (result) {
            if (result.error) {
                failed3dSecureSubscription(result.error.payment_intent.id, paymentMethodId);
            } else {
                $.ajax(
                    {
                        url: '/carts/confirm_subscription_order',
                        type: 'POST',
                        data: {payment_intent_id: result.paymentIntent.id, cart_item_id: cartItemId},
                        dataType: 'json',
                        success: function (data) {
                            handleSubscriptionOrdersServerResponse(data, paymentMethodId, data.subscription_item_ids, cartItemId, data.gift_item_ids);
                        }
                    });
            }
        });
    } else {
        var nextSubscriptionCartItem = findNextCartItem(cartItemId, subscriptionCartItems)
        if (nextSubscriptionCartItem) {
            confirmSubscriptionOrder(paymentMethodId, subscriptionCartItems, nextSubscriptionCartItem, giftCartItems)
        } else if (giftCartItems.length > 0) {
            confirmGiftOrder(paymentMethodId, giftCartItems, giftCartItems[0]);
        } else {
            submitFinalForm(paymentMethodId);
        }
    }
}

function handleGiftOrdersServerResponse(response, paymentMethodId, giftCartItems, cartItemId) {
    var stripe = Stripe($('meta[name="stripe-key"]').attr('content'), {stripeAccount: $('meta[name="stripe-account-id"]').attr('content')});
    if (response.requires_action) {
        // Use Stripe.js to handle required card action
        stripe.handleCardAction(
            response.payment_intent_client_secret
        ).then(function (result) {
            if (result.error) {
                failed3dSecureGift(result.error.payment_intent.id);
            } else {
                $.ajax(
                    {
                        url: '/carts/confirm_gift_order',
                        type: 'POST',
                        data: {payment_intent_id: result.paymentIntent.id, cart_item_id: cartItemId},
                        dataType: 'json',
                        success: function (data) {
                            handleGiftOrdersServerResponse(data, paymentMethodId, data.gift_item_ids, cartItemId);
                        }
                    });
            }
        });
    } else {
        var nextGiftCartItem = findNextCartItem(cartItemId, giftCartItems)
        if (nextGiftCartItem) {
            confirmGiftOrder(paymentMethodId, giftCartItems, nextGiftCartItem)
        } else {
            submitFinalForm(paymentMethodId);
        }
    }
}

function failed3dSecureShop(intentId, paymentMethodId) {
    $.ajax(
        {
            url: '/carts/failed_shop_3d_secure',
            type: 'POST',
            data: {payment_intent_id: intentId},
            dataType: 'json',
            success: function (data) {
                if (data.subscription_item_ids.length > 0) {
                    confirmSubscriptionOrder(paymentMethodId, data.subscription_item_ids, data.subscription_item_ids[0], data.gift_item_ids);
                } else if (data.gift_item_ids.length > 0) {
                    confirmGiftOrder(paymentMethodId, data.gift_item_ids)
                } else {
                    submitFinalForm(paymentMethodId);
                }

            }
        });
}

function failed3dSecureSubscription(intentId, paymentMethodId) {
    $.ajax(
        {
            url: '/carts/failed_subscription_3d_secure',
            type: 'POST',
            data: {payment_intent_id: intentId},
            dataType: 'json',
            success: function (data) {
                var nextSubscriptionCartItem = findNextCartItem(data.current_cart_item_id, data.subscription_item_ids)
                if (nextSubscriptionCartItem) {
                    confirmSubscriptionOrder(paymentMethodId, data.subscription_item_ids, nextSubscriptionCartItem, data.gift_item_ids)
                } else if (data.gift_item_ids.length > 0) {
                    confirmGiftOrder(paymentMethodId, data.gift_item_ids);
                } else {
                    submitFinalForm(paymentMethodId)
                }

            }
        });
}

function failed3dSecureGift(intentId, paymentMethodId) {
    $.ajax(
        {
            url: '/carts/failed_gift_3d_secure',
            type: 'POST',
            data: {payment_intent_id: intentId},
            dataType: 'json',
            success: function (data) {
                var nextGiftCartItem = findNextCartItem(data.current_cart_item_id, data.gift_item_ids)
                if (nextGiftCartItem) {
                    confirmGiftOrder(paymentMethodId, data.gift_item_ids, nextGiftCartItem)
                } else {
                    submitFinalForm(paymentMethodId);
                }
            }
        });
}

function findNextCartItem(cartItemId, cartItems) {
    if (cartItemId) {
        var currentCartItemIndex = cartItems.indexOf(cartItemId),
            newCartItemIndex = currentCartItemIndex + 1;
        if ((newCartItemIndex + 1) > cartItems.length) {
            return undefined
        } else {
            return cartItems[newCartItemIndex]
        }
    } else {
        if (cartItems.length > 1) {
            return cartItems[0]
        }
    }
}

function submitFinalForm(paymentMethodId) {
    seller_store.base.disableCloseTabPrompt();
    $('#process_order')[0].submit();
}
