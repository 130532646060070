seller_store.upsell =
    {
        changeVariant: function () {
            $('body').on('change', '#upsell-section .option-value-select', function () {
                seller_store.upsell.variantDataUpdate($(this));
            });
        },

        changeSubscription: function () {
            $('body').on('change', '#upsell-section #cart_item_plan_id', function () {
                seller_store.upsell.variantDataUpdate($(this));
            });
        },

        changeSaleOption: function () {
            $('body').on('change', '#upsell-section input[name="cart_item[sale_option]"]', function () {
                seller_store.upsell.variantDataUpdate();
            });
        },

        variantDataUpdate: function ($trigger) {
            var productId = $trigger.attr('data-product-id'),
                $productWrapper = $('#product_' + productId),
                option_value_ids = $productWrapper.find('.option-value-select').map(function () {
                    return $(this).val();
                }).get().join(',');
            saleOption = $productWrapper.find('.sale_option').val();
            planId = $productWrapper.find('.cart_item_plan_id').val();
            $.ajax(
                {
                    url: '/products/' + productId + '/variants/single',
                    type: "GET",
                    data: {option_values: option_value_ids, sale_option: saleOption, plan_id: planId},
                    dataType: "json",
                    success: function (data) {
                        $productWrapper.find('.product-variant-price').text(data.variant_price);
                        $productWrapper.find('.product-variant-id').val(data.variant_id);
                    }
                });
        },
    }
