var ready = function () {
    seller_store.base.mdbootstrap();
    seller_store.base.resizeProductListImages();
    seller_store.base.initImageLightbox();
    seller_store.base.initImageLazyLoad();
    seller_store.base.datePicker();

    seller_store.search.updateMobileOrdering();

    seller_store.collection.updateTagNameCheckboxes();
    seller_store.collection.updateOptionTypeCheckboxes();

    seller_store.product.changeVariant();
    seller_store.product.changeSubscription();
    seller_store.product.changeGift();
    seller_store.product.changeSaleOption();
    seller_store.product.buyNow();

    // seller_store.review.add();
    seller_store.review.create();
    seller_store.review.triggerEmailReview();
    seller_store.review.termsDisableSubmission();

    seller_store.cart.buildOnPageLoad();
    seller_store.cart.addCartItem();
    seller_store.cart.increaseQuantity();
    seller_store.cart.decreaseQuantity();
    seller_store.cart.removeCartItem();
    seller_store.cart.loadReorderCart();

    seller_store.checkout.validShippingTab();
    seller_store.checkout.validBillingTab();
    seller_store.checkout.continueBilling();
    seller_store.checkout.continuePayment();
    seller_store.checkout.returnToShipping();
    seller_store.checkout.updatedShippingCountry();
    seller_store.checkout.updatedShippingZip();
    seller_store.checkout.duplicateAddress();
    seller_store.checkout.termsDisablePlaceOrder();
    seller_store.checkout.loginAccountCheckout();
    seller_store.checkout.loginAccountOrderComplete();
    seller_store.checkout.useDifferentBillingAddress();
    seller_store.checkout.shippingTypeTabs();

    seller_store.order_bump.checkboxUpdate()
    seller_store.order_bump.changeVariant();

    seller_store.abandoned_cart.updateEmail();

    seller_store.pick_up.changeLocation();
    seller_store.pick_up.changeTime();
    seller_store.pick_up.updateTimes();

    seller_store.discount.displayDiscountForm();
    seller_store.discount.addToCart();

    seller_store.upsell.changeVariant();
}

$(document).ready(ready);
$(document).on('page:change turbolinks:load', function () {
});
$(document).ajaxComplete(function () {
});
